var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "armoire-empty",
      "title": _vm.$t('armoireText'),
      "size": "lg",
      "hide-footer": true
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6 offset-3"
  }, [_c('Sprite', {
    attrs: {
      "image-name": "shop_armoire"
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t('armoireLastItem')))]), _c('p', [_vm._v(_vm._s(_vm.$t('armoireNotesEmpty')))])], 1)])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "col-12 text-center"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }