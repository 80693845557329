var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.member.preferences ? _c('div', {
    staticClass: "avatar",
    class: _vm.topLevelClassList,
    style: {
      width: _vm.width,
      height: _vm.height,
      paddingTop: _vm.paddingTop
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.castEnd();
      }
    }
  }, [_c('div', {
    staticClass: "character-sprites",
    style: {
      margin: _vm.spritesMargin
    }
  }, [!_vm.avatarOnly ? [_vm.member.items.currentMount ? _c('span', {
    class: 'Mount_Body_' + _vm.member.items.currentMount
  }) : _vm._e()] : _vm._e(), _vm._l(_vm.visualBuffs, function (klass, item) {
    return [_vm.member.stats.buffs[item] && _vm.showVisualBuffs ? _c('span', {
      key: item,
      class: klass
    }) : _vm._e()];
  }), _c('span', {
    class: 'hair_flower_' + _vm.member.preferences.hair.flower
  }), _vm.showAvatar() ? [_c('span', {
    class: ['chair_' + _vm.member.preferences.chair, _vm.specialMountClass]
  }), _c('span', {
    class: [_vm.getGearClass('back'), _vm.specialMountClass]
  }), _c('span', {
    class: [_vm.skinClass, _vm.specialMountClass]
  }), _c('span', {
    class: [_vm.shirtClass, _vm.specialMountClass]
  }), _c('span', {
    class: ['head_0', _vm.specialMountClass]
  }), _c('span', {
    class: [_vm.member.preferences.size + '_' + _vm.getGearClass('armor'), _vm.specialMountClass]
  }), _c('span', {
    class: [_vm.getGearClass('back_collar'), _vm.specialMountClass]
  }), _vm._l(['bangs', 'base', 'mustache', 'beard'], function (type) {
    return [_c('span', {
      key: type,
      class: [_vm.hairClass(type), _vm.specialMountClass]
    })];
  }), _c('span', {
    class: [_vm.getGearClass('body'), _vm.specialMountClass]
  }), _c('span', {
    class: [_vm.getGearClass('eyewear'), _vm.specialMountClass]
  }), _c('span', {
    class: [_vm.getGearClass('head'), _vm.specialMountClass]
  }), _c('span', {
    class: [_vm.getGearClass('headAccessory'), _vm.specialMountClass]
  }), _c('span', {
    class: ['hair_flower_' + _vm.member.preferences.hair.flower, _vm.specialMountClass]
  }), !_vm.hideGear('shield') ? _c('span', {
    class: [_vm.getGearClass('shield'), _vm.specialMountClass]
  }) : _vm._e(), !_vm.hideGear('weapon') ? _c('span', {
    staticClass: "weapon",
    class: [_vm.getGearClass('weapon'), _vm.specialMountClass]
  }) : _vm._e()] : _vm._e(), _vm.member.preferences.sleep ? _c('span', {
    staticClass: "zzz"
  }) : _vm._e(), !_vm.avatarOnly ? [_vm.member.items.currentMount ? _c('span', {
    class: 'Mount_Head_' + _vm.member.items.currentMount
  }) : _vm._e(), _c('span', {
    staticClass: "current-pet",
    class: _vm.petClass
  })] : _vm._e()], 2), _vm.hasClass && !_vm.hideClassBadge ? _c('class-badge', {
    staticClass: "under-avatar",
    attrs: {
      "member-class": _vm.member.stats.class
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }